<script setup>

import _ from "lodash";
import { computed, ref, reactive, defineEmits, defineProps, watch } from "vue";
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useUserActions } from '@user';
import { PopUpManager as PopUp } from '@/Ship';
import { useSettingsActions } from '@settings';

const props = defineProps(['payload']);
const emit = defineEmits(['change']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();


const {
    getSales,
    getRetentions,
} = useUserActions();

const sales = ref([]);
getSales().then(response => {
    sales.value = response.data;
});

const retentions = ref([]);
getRetentions().then(response => {
    retentions.value = response.data;
    if (lead.model.retention_id) {
        retentions.value.push({
            id: null,
            name: 'None',
        });
    }
});

const statuses = computed(() => settings.statuses || []);

const labels = ref([
    { n: 'Silver', v: 'silver' },
    { n: 'Gold', v: 'gold' },
    { n: 'Green', v: 'green' },
    { n: 'Red', v: 'red' },
    { n: 'Cyan', v: 'cyan' },
    { n: 'Purple', v: 'purple' }
]);

const conversionStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'conversion'));
    if (!statuses.value.find((status) => lead.model.conversion_status === status.name && status.target === 'conversion')) {
        clonedQuotes.push({
            name: lead.model.conversion_status,
            target: 'conversion',
        });
    }

    return clonedQuotes;
});

const affiliateStatuses = computed(() => {
    return conversionStatuses.value;
})

const retentionStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'retention'));
    if (!statuses.value.find((status) => lead.model.retention_status === status.name && status.target === 'retention')) {
        clonedQuotes.push({
            name: lead.model.retention_status,
            target: 'retention',
        });
    }

    return clonedQuotes;
});

const kycStatuses = computed(() => {
    const clonedQuotes = _.cloneDeep(statuses.value.filter(status => status.target === 'kyc'));
    if (!statuses.value.find((status) => lead.model.kyc_status === status.name && status.target === 'kyc')) {
        clonedQuotes.push({
            name: lead.model.kyc_status,
            target: 'kyc',
        });
    }

    return clonedQuotes;
});

const accountLevels = computed(() => {
    return settings.model['account-levels']
        ? Object.keys(settings.model['account-levels'][process.env.VUE_APP_BRAND]).map(level => {
                return {v: level}
        })
        : [];
});

const payload = reactive({});

watch(props.payload, () => {
    if (!Object.keys(props.payload).length) {
        Object.keys(payload).forEach(key => delete payload[key]);
    }
});

function setValue(key, value) {
    if (lead.model[key] === value) {
        delete payload[key];
    } else {
        payload[key] = value;
    }

    emit('change', payload);
}

const conversionStatus = computed({
    get: () => payload.conversion_status || lead.model.conversion_status,
    set: (value) => {
        setValue('conversion_status', value);
        return true;
    }
});

const affiliateStatus = computed({
    get: () => payload.affiliate_status || lead.model.affiliate_status,
    set: (value) => {
        setValue('affiliate_status', value);
        return true;
    }
});

const retentionStatus = computed({
    get: () => payload.retention_status || lead.model.retention_status,
    set: (value) => {
        setValue('retention_status', value);
        return true;
    }
});

const KYCStatus = computed({
    get: () => payload.kyc_status || lead.model.kyc_status,
    set: (value) => {
        setValue('kyc_status', value);
        return true;
    }
});

const saleId = computed({
    get: () => payload.sale_id || lead.model.sale_id,
    set: (value) => {
        setValue('sale_id', value);
        return true;
    }
});

const retentionId = computed({
    get: () => payload.retention_id || lead.model.retention_id,
    set: (value) => {
        setValue('retention_id', value);
        return true;
    }
});

const isFTD = computed({
    get: () => payload.is_ftd || lead.model.is_ftd,
    set: (value) => {
        setValue('is_ftd', value);
        return true;
    }
});

const balance = computed({
    get: () => payload.balance || lead.model.balance,
    set: (value) => {
        setValue('balance', value);
        return true;
    }
});

const accountLevel = computed({
    get: () => payload.account_level || lead.model.account_level,
    set: (value) => {
        setValue('account_level', value);
        return true;
    }
});

const bookmarks = computed({
    get() {
        const label = payload.label || lead.model.label;
        return !!label
            ? label.split(',')
            : [];
    },
    set(value) {
        setValue('label', value.join());
        return true;
    }
});

function balanceCorrectionPopUp() {
    PopUp
        .open(require('@lead/components/PopUps/CorrectBalance'))
        .setCaption('Correct balance')
        .setPayload({payload: {
                balance: balance.value,
        }})
        .setAction(function(value) {
            lead.state[lead.index].balance = value;
            PopUp.close();
        })
}
</script>

<template>
    <fieldset v-if="profile.can('Lead ConversionStatusView')">
        <legend v-bind:class="{ changed: !!payload.conversion_status }">
            {{ $t('lead.conversion_status', 'Conversion status') }}
        </legend>
        <g-select v-model="conversionStatus"
                  v-bind:backlight="!!payload.conversion_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="conversionStatuses"
                  v-bind:readonly="profile.cant('Lead ConversionStatusEdit')"/>

    </fieldset>

    <fieldset v-if="profile.can('Lead AffiliateStatusView')">
        <legend v-bind:class="{ changed: !!payload.affiliate_status }">
            {{ $t('lead.affiliate_status', 'Affiliate Status') }}
        </legend>
        <g-select v-model="affiliateStatus"
                  v-bind:backlight="!!payload.affiliate_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="affiliateStatuses"
                  v-bind:readonly="profile.cant('Lead AffiliateStatusEdit')"/>
    </fieldset>

    <fieldset v-if="profile.can('Lead RetentionStatusView')">
        <legend v-bind:class="{ changed: !!payload.retention_status }">
            {{ $t('lead.retention_status', 'Retention Status') }}
        </legend>
        <g-select v-model="retentionStatus"
                  v-bind:backlight="!!payload.retention_status"
                  option-text="name"
                  option-value="name"
                  v-bind:options="retentionStatuses"
                  v-bind:readonly="profile.cant('Lead RetentionStatusEdit')"/>
    </fieldset>

    <fieldset v-if="profile.can('Lead KYCStatusView')">
        <legend v-bind:class="{ changed: !!payload.kyc_status }">
            {{ $t('lead.kyc_status', 'KYC Status') }}
        </legend>
        <g-select v-model="KYCStatus"
                  v-bind:backlight="!!payload.kyc_status"
                  v-bind:options="kycStatuses"
                  option-text="name"
                  option-value="name"
                  v-bind:readonly="profile.cant('Lead KYCStatusEdit')"/>
    </fieldset>

    <fieldset v-if="profile.can('Lead SaleView')">
        <legend v-bind:class="{ changed: !!payload.sale_id }">
            {{ $t('lead.sale', 'Sale agent') }}
        </legend>
        <g-select v-model="saleId"
                  v-bind:backlight="!!payload.sale_id"
                  v-bind:options="sales"
                  option-text="name"
                  option-value="id"
                  v-bind:readonly="profile.cant('Lead SaleEdit')"/>
    </fieldset>

    <fieldset v-if="profile.can('Lead RetentionView')">
        <legend v-bind:class="{ changed: !!payload.retention_id }">
            {{ $t('lead.retention', 'Retention agent') }}
        </legend>
        <g-select v-model="retentionId"
                  v-bind:backlight="!!payload.retention_id"
                  v-bind:options="retentions"
                  option-text="name"
                  option-value="id"
                  v-bind:readonly="profile.cant('Lead RetentionEdit') || lead.model.is_ftd === 'No'"/>
    </fieldset>

    <fieldset v-if="profile.can('Lead IsFTDView')">
        <legend v-bind:class="{ changed: !!payload.is_ftd }">
            {{ $t('lead.is_ftd', 'Is FTD') }}
        </legend>
        <g-select v-model="isFTD"
                  v-bind:backlight="!!payload.is_ftd"
                  v-bind:options="[{value: 'No'}, {value: 'Yes'}]"
                  option-text="value"
                  option-value="value"
                  v-bind:readonly="profile.cant('Lead IsFTDEdit')"/>
    </fieldset>
    <fieldset v-if="profile.can('Lead BalanceView')">
        <legend v-bind:class="{ changed: !!payload.balance }">
            {{ $t('lead.balance', 'Balance') }}
        </legend>
        <g-input v-model="balance" v-bind:backlight="!!payload.balance" readonly>
            <g-symbol name="lock" width="18" height="18"/>
            <g-symbol v-if="profile.can('Lead BalanceEdit')"
                      v-on:click="balanceCorrectionPopUp"
                      name="edit"
                      width="20"
                      height="20"/>
        </g-input>
    </fieldset>

    <fieldset v-if="profile.can('Lead AccountLevelView')">
        <legend v-bind:class="{ changed: !!payload.account_level }">
            {{ $t('lead.account_level', 'Account level') }}
        </legend>
        <g-select v-model="accountLevel"
                  v-bind:backlight="!!payload.account_level"
                  v-bind:options="accountLevels"
                  option-text="v"
                  option-value="v"
                  v-bind:readonly="profile.cant('Lead AccountLevelEdit')"/>

    </fieldset>

    <fieldset>
        <legend>Label</legend>
        <g-fade-container class="bookmarks"
                          icon="label"
                          v-bind:label="(payload.label || lead.model.label).split(',').join(', ')">
            <div class="labels">
                <g-checkbox v-for="label in labels"
                            v-model="bookmarks"
                            v-bind:value="label.v"
                            v-bind:key="label.v"
                            v-bind:class="label.v"
                            v-bind:disabled="profile.cant('Lead LabelEdit')">
                    {{ label.n }}
                </g-checkbox>
            </div>
        </g-fade-container>
    </fieldset>
</template>

<style scoped lang="scss">
legend.changed {
    color: var(--seconsary, $secondary);
}

.balance > .g-field {
    flex-grow: 1;
}

.edit-btn {
    width: 45px;
    position: relative;
    color: var(--title-color, $title-color);
    background-color: var(--env-bg, $env-bg);
    fill: var(--success, $success);
    border: 1px solid var(--success, $success);

    &::after {
        left: 20%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        border-bottom-color: var(--tooltip-bg, $tooltip-bg);
    }

    &:hover {
        background-color: var(--success, $success);
        fill: var(--btn-primary-color, $btn-primary-color);
    }
}

.bookmarks {
    border: 1px solid var(--field-border, $field-border);

    &:deep(input) {
        text-transform: capitalize;
    }

    &:deep(.icon) {
        fill: var(--danger, $danger);
    }
}

.labels {
    column-count: 2;
    padding: 16px;
    border-radius: 9px;
    color: var(--title-text-color, $title-text-color);
    background-color: var(--secondary, $secondary);

    & > .g-checkbox {
        width: 100%;
        margin: 5px 0;
        display: inline-block;

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }
    }
}
</style>