import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Payment extends BaseResource {

    static #instance = null;

    endpoint = 'payments';

    section = 'Finances';
    container = 'Finances';

    static getInstance() {
        if (!Payment.#instance) {
            Payment.#instance = new Payment();
        }
        return Payment.#instance;
    }
}
