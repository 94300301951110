<script>
import { ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useFinanceComponents } from '@finance';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const {
    LeadWallets,
    LeadDeposits,
    LeadWithdraws,
} = useFinanceComponents();
export default {
    components: {
        LeadWallets,
        LeadDeposits,
        LeadWithdraws,
    },

    setup() {
        const tab = ref('LeadDeposits');
        const profile = getProfileResource();

        function makeDepositPopUp() {
            PopUp.open(require('@finance/components/PopUps/MakeDeposit'))
                .setCaption(i18n.global.t('finances.make_deposit', 'Make Deposit'));
        }

        return {
            tab,
            profile,
            makeDepositPopUp
        }
    }
}
</script>

<template>
    <div class="tab-bar">
        <span v-if="profile.can('Wallet View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadWallets'}"
              v-on:click="tab = 'LeadWallets'">
            {{ $t('finances.payments', 'Payments') }}
        </span>
        <span v-if="profile.can('Deposit View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadDeposits'}"
              v-on:click="tab = 'LeadDeposits'">
            {{ $t('finances.deposits', 'Deposit') }}
        </span>
        <span v-if="profile.can('Withdraw View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'LeadWithdraws'}"
              v-on:click="tab = 'LeadWithdraws'">
            {{ $t('finances.withdraws', 'Withdraws') }}
        </span>

        <div class="toolbar">
            <g-button v-if="profile.can('Deposit Make')"
                      class="with-tooltip"
                      type="button"
                      v-on:click="makeDepositPopUp"
                      v-bind:data-tooltip="$t('finances.make_deposit', 'Make Deposit')">
                <g-symbol name="plus" width="15" height="15"/>
            </g-button>
        </div>
    </div>
    <component v-bind:is="tab"/>
</template>

<style lang="scss" scoped>
.tab-bar {
    padding: 10px 50px 10px 60px;
    z-index: 2;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    background-color: var(--env-bg, $env-bg);

    & > .tab-btn {
        font-size: 1.2rem;
        color: var(--env-text-color, $env-text-color);

        &.active {
            font-weight: bold;
            color: var(--env-title-color, $env-title-color);
        }
    }
}

.toolbar {
    flex-grow: 1;
    text-align: right;

    & > .g-button {
        width: 32px;
        height: 32px;
        color: white;
        margin: 0 2px;
        font-size: 0;
        background-color: transparent;
        fill: var(--env-text-color, $env-text-color);
        border: 1px solid var(--env-text-color, $env-text-color);
        position: relative;

        &:hover {
            border-color: var(--success, $success);
            background-color: var(--success, $success);
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        &::after {
            top: 50%;
            line-height: 1;
            padding: 8px;
            transform: translateY(-50%);
            right: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            right: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-left-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}
</style>