<script setup>

import { ref, reactive, computed } from "vue";
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getPaymentResource = useFinanceActions('getPaymentResource');
const payments = getPaymentResource({lead_id: [lead.model.id]}, true);

const currency = ref('Bitcoin');
const currencies = ref([
    { v: 'Bitcoin' },
    { v: 'Ethereum' },
    { v: 'Tron' },
    { v: 'Tether (ERC-20)' },
    { v: 'Tether (TRC-20)' },
]);

const provider = ref('Stripe');
const providers = ref([
    { v: 'Stripe' },
    { v: 'Zelle' },
    { v: 'CashApp' },
    { v: 'PayPal' },
]);

const payload = reactive({
    lead_id: lead.model.id,
    type: 'Crypto',
    method: currency.value,
    is_active: 1,
});

const type = computed({
    get: () => payload.type,
    set: value => {

        payload.method = (payload.method === 'Crypto')
            ? currency.value || 'Bitcoin'
            : provider.value || 'Stripe';

        delete payload.details;
        delete payload.name;

        payload.type = value;

        return true;
    }
});

const method = computed({
    get: () => payload.type === 'Crypto' ? currency.value : provider.value,
    set: value => {

        if (payload.type === 'Crypto') {
            currency.value = value;
        } else {
            provider.value = value;

            value === 'Zelle'
                ? payload.name = ''
                : delete payload.name
        }

        payload.method = value;
        return true;
    }
});

const name = ref(''); // Ім'я для Zelle

function addPayment() {
    payments.create(payload);
}

function disactivate(index) {
	payments.state[index].is_active = !payments.state[index].is_active;
	payments.save(payments.state[index].id, {
		is_active: payments.state[index].is_active
	});
}

function remove(index) {
	payments.delete(payments.state[index].id).then(() => {
		payments.state.splice(index, 1);
	});
}

</script>

<template>
    <div class="payments">
        <table>
            <tbody>
            <tr v-for="(payment, index) in payments.state" :key="`payment-${index}`" :class="{ active: payment.is_active }">
                <td class="cell">
                    <g-flex align="center" gap="5">
                        <g-symbol-currency v-bind:name="{ 'Bitcoin': 'btc', 'Ethereum': 'eth', 'Tron': 'trx', 'Tether (ERC-20)': 'usdt', 'Tether (TRC-20)': 'usdt' }[payment.method] || 'usd'"
                                           width="20"
                                           height="20" />
                        <span>{{ payment.method }}</span>
                    </g-flex>
                </td>
                <td class="cell address">
                    <g-copy v-bind:text="payment.details" />
                </td>
                <td class="cell actions">
                    <g-symbol v-bind:name="payment.is_active ? 'check' : 'close'" class="is-active" width="16" height="16" v-on:click="disactivate(index)" />
                    <g-symbol name="delete" class="delete" width="16" height="16" v-on:click="remove(index)" />
                </td>
            </tr>
            </tbody>
        </table>

        <div class="controls">
            <!-- Вибір типу платежу -->
            <g-flex justify="end">
                <g-fade-container class="type-menu" icon="card" v-bind:label="type">
                    <div class="payment-type-container">
                        <label class="type-item"><input v-model="type" type="radio" name="type" value="Crypto" hidden/>Crypto</label>
                        <label class="type-item"><input v-model="type" type="radio" name="type" value="Card" hidden/>Card</label>
                    </div>
                </g-fade-container>
            </g-flex>

            <g-flex align="end" gap="10">
                <fieldset class="provider" >
                    <legend v-if="payload.type === 'Crypto'">Currency</legend>
                    <legend v-else>Provider</legend>
                    <g-select v-model="method"
                              v-bind:options="payload.type === 'Crypto' ? currencies : providers"
                              option-text="v"
                              option-value="v"/>
                </fieldset>

                <fieldset class="name" v-if="payload.type === 'Card' && provider === 'Zelle'">
                    <legend>Name</legend>
                    <g-input v-model="payload.name"/>
                </fieldset>

                <fieldset class="details">
                    <legend>
                        <template v-if="payload.type === 'Crypto'">Address</template>
                        <template v-else-if="provider === 'Stripe'">Link</template>
                        <template v-else-if="provider === 'Zelle'">Email/Phone</template>
                        <template v-else-if="provider === 'CashApp'">CashTag/Email/Phone</template>
                        <template v-else>Email</template>
                    </legend>
                    <g-input v-model="payload.details"/>
                </fieldset>

                <g-button class="add-btn" v-on:click="addPayment">
                    <g-symbol name="import" width="22" height="22" />
                </g-button>
            </g-flex>
        </div>
    </div>
</template>

<style scoped lang="scss">
.payments {
    padding: 20px 50px 20px 60px;
	background-color: var(--env-bg, $env-bg);
    //color: var(--env-text-color, $env-text-color);
}

table {
	width: 100%;
	font-size: 0.9rem;
	white-space: nowrap;
    color: var(--env-text-color, $env-text-color);
    overflow: hidden;
    //background-color: var(--primary, $primary);
    //border-radius: var(--bar-rounding, $bar-rounding);
}

tr {
	//&:nth-child(odd) {
    //    background-color: var(--primary-hover, $primary-hover);
	//}
	.g-symbol {
		margin: 0 4px;

		&.delete {
			fill: var(--env-text-color, $env-text-color);

			&:hover {
				fill: var(--env-title-color, $env-title-color);
			}
		}
	}

	&.active > .actions > .is-active {
		fill: var(--success, $success);

		&:hover {
			fill: var(--success-hover, $success-hover);
		}
	}

	&:not(.active) > .actions > .is-active {
		fill: var(--danger, $danger);

		&:hover {
			fill: var(--danger-hover, $danger-hover);
		}
	}
}

td {
	padding: 10px 8px;

	&.address {
		width: 100%;
	}

	&.actions {
		text-align: center;

		& > .g-symbol {
			cursor: pointer;
		}
	}
}

.g-symbol-currency {
    background-color: #000000aa;
    border-radius: 50%;
}

.controls {
	margin-top: 15px;
    color: var(--env-text-color, $env-text-color);
    border-top: 1px solid var(--separator-color, $separator-color);

    .payment-type-container {
        padding: 10px;
        background-color: var(--primary, $primary);

        & > .type-item {
            display: block;
            padding: 5px;
            margin: 2px 0;
            cursor: pointer;
            font-size: 0.9rem;
            color: var(--main-text-color, $main-text-color);
            fill: var(--main-text-color, $main-text-color);

            &:hover {
                color: var(--title-text-color, $title-text-color);
                fill: var(--title-text-color, $title-text-color);
                border-radius: 5px;
                //background-color: var(--primary, $primary);
            }
        }
    }

    .provider {
        width: 140px;
    }

	.details {
        flex-grow: 1;
    }

	.currency {
		width: 160px;

        & > .g-select {
            color: var(--env-text-color, $env-text-color);
        }
	}

	.add-btn {
		color: white;
		padding: 0 15px;
		background-color: var(--save-btn-bg, $save-btn-bg);

		svg {
			fill: white;
		}
	}
}

</style>