<template>
    <g-fade-container class="profile-menu"
                      v-bind:class="{'impersonated': isImpersonated}"
                      icon="home"
                      v-bind:label="profile.name">
        <div class="account-menu">
            <router-link v-bind:to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                         class="menu-item"
                         v-bind:class="{ active: $route.name === 'Profile' }">
                <g-symbol name="profile" width="24" height="22"/>
                {{ $t('auth.profile', 'Profile') }}
            </router-link>
            <label class="menu-item"
                   v-if="isImpersonated"
                   v-on:click="backToAccount">
                <g-symbol name="incognito" width="24" height="24"/>
                {{ $t('auth.cancel_impersonation', 'Cancel Impersonation') }}
            </label>
            <label class="menu-item"
                   v-else
                   v-show="isAdmin"
                   v-on:click="PopUp.open(require('@profile/components/PopUps/Impersonatation.vue')).setCaption('Impersonate')">
                <g-symbol name="incognito" width="24" height="20"/>
                {{ $t('auth.impersonation') }}
            </label>
            <label class="menu-item" v-on:click="logout">
                <g-symbol name="logout" width="24" height="24"/>
                {{ $t('auth.logout') }}
            </label>
            <!--
            <hr class="separator"/>
            <label class="menu-item theme-switcher">
                {{ $t('base.light_mode') }}
                <g-switch v-on:update:checked="onChangeTheme($event)" v-bind:checked="STATE.IS_LIGHT_MODE"/>
            </label>
            -->
        </div>
    </g-fade-container>
</template>

<script>

import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useAuthActions } from '@auth';
import { useProfileActions, useProfileResources } from '@profile';

const signOut = useAuthActions('signOut');
const {
    cancelImpersonation,
    getProfileResource
} = useProfileActions();

export default {
    name: 'AccountMenu',

    setup() {
        const router = useRouter();
        const profile = getProfileResource();

        const isImpersonated = computed(() => sessionStorage.getItem('impersonate'));
        const isAdmin = computed(() => profile.roles.map(role => role.name).includes('Admin'));

        const logout = () => {
            signOut();
            setTimeout(() => router.push({ name: 'SignIn' }), 0);
        };

        const backToAccount = () => {
            const id = sessionStorage.getItem('impersonate');
            if (id) {
                cancelImpersonation(id);
            }
        };

        return {
            STATE,
            PopUp,
            profile,
            signOut,
            isAdmin,
            isImpersonated,
            logout,
            backToAccount
        };
    }
};
</script>

<style lang="scss" scoped>
.g-fade-container {
    &:deep(.icon) {
        fill: white;
        margin-right: 8px;
        border-radius: 10px;
        background-color: var(--primary, $primary);
    }

    &:deep(label) {
        border-radius: 10px;
    }

    &:deep(input) {
        text-align: center;
        border-radius: 10px;
    }

    &.active {
        &:deep(.label) {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.account-menu {
    padding: 10px;
    background-color: var(--primary, $primary);

    & > .menu-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        margin: 2px 0;
        cursor: pointer;
        font-size: 0.9rem;
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);
            border-radius: 5px;
            //background-color: var(--primary, $primary);
        }

        &.disabled {
            pointer-events: none;
            color: var(--disabled-color, $disabled-color);
            fill: var(--disabled-color, $disabled-color);
        }

        &.theme-switcher {
            white-space: nowrap;
            justify-content: space-between;
        }
    }

    & > .separator {
        height: 1px;
        border: none;
        margin: 10px 0;
        background-color: var(--separator-color, $separator-color);
    }
}
</style>