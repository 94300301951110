import { useFinanceResources } from '@finance';

export default (filters, reload = false) => {
    const payment = useFinanceResources('Payment');

    if (reload || (!payment.isLoaded && !payment.isLoading)) {

        //payment.setIncludes();
        payment.setFilters(filters)
        //payment.sort('id', 'desc');

        payment.load();
    }

    return payment;
};

