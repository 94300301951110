<script setup>

import { ref, defineEmits, defineProps } from 'vue';
import { i18n } from '@/Ship';
import { useFinanceActions } from '@finance';
import { useLeadActions } from '@lead';

const props = defineProps(['payload']);
const emit = defineEmits(['close']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getWithdrawResource = useFinanceActions('getWithdrawResource');
const withdraw = getWithdrawResource();

const index = withdraw.state.findIndex(order => order.id === props.payload.id);

function removeOrder() {
    withdraw.delete(props.payload.id).then(() => {

        withdraw.state.splice(index, 1);
        emit('close');
    });

}
</script>

<template>
    <div class="message">
        <g-caption align="center" size="4">{{ i18n.global.t('finances.remove_order', 'Remove order') }}?</g-caption>
    </div>
    <g-flex class="btn-bar" justify="center" gap="5">
        <g-button class="cancel-btn" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
        <g-button class="apply-btn" v-on:click="removeOrder">{{ $t('base.remove', 'Remove') }}</g-button>
    </g-flex>
</template>

<style scoped lang="scss">
.message {
    max-width: 320px;
}

.g-caption {
    margin-bottom: 15px;
}

.btn-bar {
    padding-top: 25px;
}

.g-button {
    color: white;
    width: 120px;

    &.cancel-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
    }

    &.apply-btn {
        background-color: var(--danger, $danger);

        &:hover {
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}
</style>